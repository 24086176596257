import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const There = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>submit: (t)here (project C)</h2>
        <p>In the next class, <Link to="../there">Project C</Link> is due.</p>

        <br></br>
        <p>The presentation of this project will be longer than previous ones. It includes a demo* in which a number of people* engage with your page. For this, try to anticipate any confusion a user might have and create precautions. Your project should work for everyone.</p>
        <br></br>
        <p>In addition, go to this <a targe="_blank" href="https://docs.google.com/presentation/d/1l64PxqQNoajo1G3RvH2oNTnU26Bea93hNZS1RiaPyno/edit?usp=sharing">slide deck</a> and</p>
        <p>- prepare one slide with a GIF that presents your project in its best light (<a target="_blank" href="https://docs.google.com/presentation/d/1KjjRXPjuEEtqOGh1Jeyr6beXEbGaL3WgpjeLPUYvL5w/edit?usp=sharing">screenshot advice</a>), the project name, your name(s) and a link. </p>
        <p>- one slide with a diagram that attempts to explain the projects inner workings.</p>
        <br></br>
        <p>Prepare a presentation that includes (1) an intro, (2) a demo (6 minutes), (3) a technical overview using the slide mentioned above, and (4) an overview of challenges you faced, solutions you found, compromises you made and shortcomings of your project. It is up to, in which order (1-4) occur. You will have up to 10 minutes in total.</p>
        <br></br>
        <br></br>
        <p>This is <b>due on Tuesday, December 13</b> for everyone. If you would like to present on that day, <b>please contact me asap</b> — in the event of no one signing up this way, presenters will be picked at random. </p>
        <br></br>
        <br></br>
        <p>*this could be the whole class, or fewer, depending on server speed (you decide). </p>

      </MiniLayout>
    </Layout>
  )
}



export default There
